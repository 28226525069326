import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { Location } from '../interfaces/gatsby'

interface Props {
  location: Location
}

const NotFoundPage = ({ location }: Props) => {
  useEffect(() => {
    if (location.pathname.split('/')[1] === 'en') navigate('/en')
    else navigate('/')
  }, [location.pathname])

  return <div />
}

export default NotFoundPage
